/**
 * This file is preprocessed to add build specific functionality for browser and nodejs
 * environments, the original file lives under the `PROJECT/hbs` folder.
 */
import { ParameterMap } from './ParameterMap'
import { QueryError } from './QueryError'
/* Node environment only. */

/**
 * Smm parameter has not been loaded yet.
 */
export class ParameterNotInCdkContextError extends Error {
  constructor () {
    super()
    Error.captureStackTrace(this, ParameterNotInCdkContextError.constructor)
  }
}

export interface DeployPrivateClientOptions {
  readonly context: any
}

export abstract class DeployPrivateClient {
  readonly context: any

  constructor (context: any) {
    this.context = context
  }

  static create (options: DeployPrivateClientOptions): DeployPrivateClient {
    let client: DeployPrivateClient | undefined

    /* Node environment only. */

    // Fallback to error client
    if (client === undefined) {
      client = new PrivateClientErrorImpl(options.context)
    }

    return client
  }

  /**
   * @throws ParameterNotInCdkContextError when the parameter still needs to be loaded
   * @param parameter
   */
  public abstract queryParameter (parameter: string): ParameterMap
}

export class PrivateClientErrorImpl extends DeployPrivateClient {
  public queryParameter (key: string): ParameterMap {
    console.error('Application could not load required config client.')
    throw new QueryError('query could not completed, application configuration error')
  }
}

/* Node environment only. */
