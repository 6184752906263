import React, { FC, memo } from 'react'
import { styled } from '@mui/material/styles'
import { ContainerProps, Toolbar, ToolbarProps, Grid, Paper } from '@mui/material'
import HuiTabs from './TabNavigation'

import { HuiContentWrapperProps, HuiTabsProps } from './types'

interface styledToolbarType extends ToolbarProps {
  component?: any
  position?: string
}

const ContentWrapper = styled(Paper, { shouldForwardProp: prop => prop !== 'open' })<ContainerProps>(({ theme }) => ({
  zIndex: 0,
  backgroundColor: theme.palette.common.white,
  padding: '0 !important'
}))

const HeaderBar = styled(Toolbar, { shouldForwardProp: prop => prop !== 'open' })<styledToolbarType>(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  backgroundColor: theme.palette.common.headerBackground.main,
  marginBottom: theme.spacing(0),
  marginTop: 'auto'
}))

const Wrapper: FC<HuiContentWrapperProps> = props => {
  const args = { ...{ direction: 'column', sx: { p: 2, m: 5 } }, ...props }

  const slectedTab = props.tabConfig?.findIndex((o: HuiTabsProps) => o.selected === true)

  return (
    <>
      <HuiTabs.Tabs defaultValue={slectedTab ?? 0}>
        {((args.tabConfig !== undefined && args.tabConfig.length > 0) || args.headerContent !== undefined) && (
          <HeaderBar component='header' sx={{ justifyContent: 'end' }}>
            {args.headerContent ?? args.headerContent}
            {args.tabConfig != null && args.tabConfig.length > 0 && (
              <HuiTabs.TabList>
                {args.tabConfig?.map((o: HuiTabsProps, i: number) => (
                  <HuiTabs.Tab key={`hui-content-header-tab-${i}`} {...o}>
                    {o.name}
                  </HuiTabs.Tab>
                ))}
              </HuiTabs.TabList>
            )}
          </HeaderBar>
        )}

        <ContentWrapper sx={{ p: 0, my: 0 }} elevation={0}>
          <Grid
            {...args}
            container
            direction={props.direction}
            style={{ width: 'initial' }}
            // component='main'
            aria-label={args['aria-label']}
            id='atlas-main-content'
            tabIndex={-1}
          >
            {args.tabConfig != null && args.tabConfig.length > 0
              ? args.tabConfig.map((o: HuiTabsProps, i: number) => (
                <HuiTabs.TabPanel value={i} key={`content-tab-${i}`}>
                  {o.content}
                </HuiTabs.TabPanel>
              ))
              : args.children}
          </Grid>
        </ContentWrapper>
      </HuiTabs.Tabs>
    </>
  )
}

export const HuiContentWrapper = memo(Wrapper)
