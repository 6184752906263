import React, { Suspense, useEffect, useState } from 'react'
import { HuiLoader, HuiLoaderProps } from '@mit/hui-core'
import { Region } from '../authorization'
import { SessionExpirationModal } from './SessionExpirationModal'
import { EventBus } from '../common'
import { AppInitializationDto, AppConfig, RegionalConfig } from '../types'
import { WithRegionContext } from '../hoc'
import { useRegionContext } from '../context'

interface Props {
  App: React.LazyExoticComponent<React.FC<{}>>
  appInitializer: () => void
  appConfig: AppConfig
  loaderProps: HuiLoaderProps
  useCognitoAuthentication: boolean
}

const InitializationImplementation: React.FC<Props> = ({ App, appInitializer, appConfig, loaderProps, useCognitoAuthentication }) => {
  const [initializeDto, setinitializeDto] = useState<AppInitializationDto>({
    isLoading: true,
    hasAccess: true,
    hasError: false
  })
  const [sessionExpired, setSessionExpired] = useState<boolean>(false)
  const [regionConfig, setRegionConfig] = useState<RegionalConfig | null>(null)
  const { hasOutage, activeRegionInitialized, setHasOutage, setActiveRegionInitialized } = useRegionContext()

  console.log('setRegionConfig123', setRegionConfig)

  // Set Up Listeners For Global Events
  useEffect(() => {
    EventBus.subscribe('APP_INITIALIZED', (dto: AppInitializationDto) => {
      setinitializeDto(dto)
    })
    EventBus.subscribe('REGION_INITIALIZED', () => {
      setActiveRegionInitialized(true)
      appInitializer()
    })
    EventBus.subscribe('REGION_OUTAGE', () => setHasOutage(true))
    EventBus.subscribe('SESSION_EXPIRED', () => setSessionExpired(true))
    EventBus.subscribe('CHECK_REGION_UPDATE', () => console.log('CHECK_REGION_UPDATE'))

    return () => {
      EventBus.unsubscribe('APP_INITIALIZED')
      EventBus.unsubscribe('REGION_INITIALIZED')
      EventBus.unsubscribe('REGION_OUTAGE')
      EventBus.unsubscribe('SESSION_EXPIRED')
    }
  }, [])

  // Initialize Active Region
  useEffect(() => {
    const region = new Region(appConfig)
    region
      .initializeActiveRegion(useCognitoAuthentication)
      .then(_ => {})
      .catch(error => {
        console.error(error)

        setinitializeDto({
          isLoading: false,
          hasAccess: false,
          hasError: true
        })
      })

    // eslint-disable-next-line
  }, [])

  const getLoaderType = (): 'Default' | 'Outage' | 'No Access' => {
    if (hasOutage) return 'Outage'

    if (initializeDto.hasError) return 'No Access'

    if (initializeDto.hasAccess || initializeDto.isLoading) return 'Default'

    return 'No Access'
  }

  if (initializeDto.isLoading || !initializeDto.hasAccess || initializeDto.hasError || hasOutage || !activeRegionInitialized) {
    return (
      <>
        {activeRegionInitialized}
        <HuiLoader
          {...loaderProps}
          isLoading={initializeDto.isLoading}
          type={getLoaderType()}
          // type={hasOutage ? 'outage' : initializeDto.hasAccess || initializeDto.isLoading ? 'default' : 'noAccess'}
        />
      </>
    )
  }

  return (
    <>
      {sessionExpired && <SessionExpirationModal regionConfig={regionConfig as RegionalConfig} />}
      <Suspense fallback={'loading...'}>
        <App />
      </Suspense>
    </>
  )
}

export const Initialization = WithRegionContext(InitializationImplementation)
