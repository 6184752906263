import React, { FC, useState, useEffect, isValidElement, HTMLAttributeAnchorTarget } from 'react'

import { useLocation, Link, useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga'
import { styled, Theme, CSSObject, alpha, useTheme } from '@mui/material/styles'
import {
  AppBar,
  Toolbar,
  Drawer,
  Typography,
  Tooltip,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  Divider,
  ToolbarProps,
  ListProps,
  ListItemButtonProps,
  GridProps,
  useMediaQuery,
  Chip,
  Container,
  Paper,
  DrawerProps,
  ContainerProps,
  PaperProps,
  Fade
} from '@mui/material'

import { AtlasLogo } from './AtlasLogo'
import { Spacer } from '../Spacer'

import { ProfileAvatar, UtilityNavigation, HuiGlobalAtlasNavigation, HuiIcon } from '..'

import AtlasLayoutProps, { NavigationItemProps, UtilityNavigationItemProps } from './types'
import { UTILS } from '../../'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { envKeys } from '../../types'
import { TypographyProps } from '@mui/system'

interface styledGridType extends GridProps {
  component?: any
  open: boolean
}

interface styledTypographyType extends TypographyProps {
  component?: any
  item?: boolean
}

const openedMixin = (theme: Theme, drawerwidth: number): CSSObject => ({
  width: drawerwidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
})

const closedMixin = (theme: Theme, drawerwidth: number): CSSObject => ({
  width: drawerwidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden'
})

const openedMixinMobile = (theme: Theme): CSSObject => ({
  width: '100%',
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
})

const closedMixinMobile = (theme: Theme): CSSObject => ({
  width: '100%',
  height: 0,
  overflowX: 'hidden',
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
})

const excludeProps: PropertyKey[] = ['isSmall', 'hasNav', 'drawerWidth']

const SkipToContentLink = styled(Button, { shouldForwardProp: () => true })<{ open?: boolean }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  transform: 'translateY(-100%)',
  transition: 'transform 0.3s',
  zIndex: 10000,
  '&:focus': {
    transform: 'translateY(0%)'
  }
}))

interface AtlasWrapperProps extends ContainerProps {
  open: boolean
  drawerwidth: number
}

const AtlasWrapper = styled(Container, { shouldForwardProp: prop => !excludeProps.includes(prop) })<AtlasWrapperProps>(
  ({ theme, open, drawerwidth }) => ({
    position: 'relative',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    width: 'initial',
    height: '100vh',
    maxWidth: 'initial !important',
    padding: theme.spacing(0),
    marginLeft: drawerwidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: drawerwidth
    })
  })
)

interface MainNavProps extends DrawerProps {
  drawerwidth: number
}

const MainNav = styled(Drawer, { shouldForwardProp: prop => !excludeProps.includes(prop) })<MainNavProps>(({ theme, open, drawerwidth }) => ({
  width: drawerwidth,
  border: 0,
  borderRight: 0,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  ...(open === true ? { ...openedMixin(theme, drawerwidth) } : { ...closedMixin(theme, drawerwidth) }),
  '& .MuiDrawer-paper': {
    ...(open === true ? { ...openedMixin(theme, drawerwidth) } : { ...closedMixin(theme, drawerwidth) })
  },
  [theme.breakpoints.down('sm')]: {
    ...(open === true ? { ...openedMixinMobile(theme) } : { ...closedMixinMobile(theme) }),
    '& .MuiDrawer-paper': {
      ...(open === true ? { ...openedMixinMobile(theme) } : { ...closedMixinMobile(theme) })
    }
  }
}))

interface ContentWrapperProps extends PaperProps {
  isSmall: boolean
  hasNav: boolean
}

const ContentWrapper = styled(Paper, { shouldForwardProp: prop => !excludeProps.includes(prop) })<ContentWrapperProps>(
  ({ theme, isSmall, hasNav }) => ({
    borderRadius: isSmall || !hasNav ? '0' : '10px 0 0 10px',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    overflow: 'hidden auto'
  })
)

interface styledListType extends ListProps {
  component?: any
  isSmall?: boolean
}

const NavList = styled(List, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledListType>(({ theme, isSmall }) => ({
  height: 'inherit',
  color: alpha(theme.palette.common.white, 1),
  fontSize: '14px',
  padding: isSmall === false ? '0 !important' : '35px 0 20px 0 !important'
}))

interface styledNavItemType extends ListItemButtonProps {
  component?: any
  to?: string
  href?: string
  target?: HTMLAttributeAnchorTarget
}

const NavItem = styled(ListItemButton, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledNavItemType>(({ theme }) => ({
  borderRadius: '6px !important',
  width: 'inherit',
  margin: '0 20px',
  marginBottom: '0.5rem',
  padding: '5px 10px',
  minHeight: '38px',
  '&:hover': {
    cursor: 'pointer',
    background: alpha(theme.palette.common.black, 0.25),
    color: alpha(theme.palette.common.white, 1)
  },
  '&.Mui-selected': {
    cursor: 'no-drop',
    background: alpha(theme.palette.common.black, 0.25)
  },
  '&.Mui-selected:hover': {
    cursor: 'no-drop',
    background: alpha(theme.palette.common.black, 0.25)
  },
  '$.Mui-focusVisible': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  }
}))

const NavItemIcon = styled(ListItemIcon, { shouldForwardProp: prop => !excludeProps.includes(prop) })(({ theme }) => ({
  fontSize: '18px !important',
  lineHeight: '18px !important',
  fontWeight: '900 !important',
  minWidth: '22px !important',
  marginRight: '11px',
  color: theme.palette.common.white
}))

const NavItemText = styled(ListItemText, { shouldForwardProp: prop => !excludeProps.includes(prop) })(() => ({
  lineHeight: '19.07px',
  fontSize: '14px !important',
  fontWeight: '600',
  whiteSpace: 'normal',
  '&.primary': {
    lineHeight: '19.07px',
    fontSize: '14px !important',
    fontWeight: '600',
    whiteSpace: 'normal'
  }
}))

const NavItemTextPrimary = styled(Typography, { shouldForwardProp: prop => !excludeProps.includes(prop) })(() => ({
  lineHeight: '19.07px',
  fontSize: '14px !important',
  fontWeight: '600',
  whiteSpace: 'normal'
}))

interface styledToolbarType extends ToolbarProps {
  component?: any
  drawerWidth: number
  isSmall: boolean
  hasNav: boolean
  open?: boolean
}

const Header = styled(AppBar, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledToolbarType>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    zIndex: '10 !important',
    backgroundColor: '#E1E6EC !important'
  }
}))

const HeaderBar = styled(Toolbar, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledToolbarType>(({ theme, open }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.common.headerBackground.main, 1),
  '@media all': {
    minHeight: 10
  },
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: open === true ? '0 0 0 0' : '0 0 10px 10px'
  }
}))

const HeaderBarExtension = styled(Toolbar, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledToolbarType>(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.common.headerBackground.main, 1),
  '@media all': {
    minHeight: 10
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}))

const NavHeaderItem = styled(ListItemButton, { shouldForwardProp: prop => !excludeProps.includes(prop) })(() => ({
  fontSize: '16px !important',
  width: 'inherit',
  margin: '0',
  padding: '12px 0',
  '&:hover': {
    cursor: 'pointer'
  }
}))

const NavHearderItemTextContainer = styled(Grid, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledGridType>(({ theme, open }) => ({
  display: open ? 'flex' : 'none',
  width: open ? 'initail' : 0,
  transition: theme.transitions.create(['display', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    delay: theme.transitions.duration.leavingScreen
  })
}))

const NavHeaderItemText = styled(Typography, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledTypographyType>(() => ({
  lineHeight: '22px',
  whiteSpace: 'break-spaces'
}))

const NavHeaderItemSubText = styled(Typography, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledTypographyType>(() => ({
  whiteSpace: 'break-spaces',
  fontWeight: '300',
  fontSize: '13px',
  lineHeight: '15.5px',
  letterSpacing: '-0.41 px'
}))

const NavHeaderItemIcon = styled(ListItemIcon, { shouldForwardProp: prop => !excludeProps.includes(prop) })(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  margin: '0 12px',
  width: 'inherit',
  padding: 0
}))

const CollapseButton = styled(Grid, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledGridType>(({ theme }) => ({
  background: 'none',
  color: 'inherit',
  border: 'none',
  font: 'inherit',
  borderRadius: '6px !important',
  padding: '10px',
  margin: '0 20px',
  width: 'initial !important',
  marginBottom: '27px !important',
  '&:focus': {
    background: alpha(theme.palette.common.black, 0.25)
  },
  '&:hover': {
    cursor: 'pointer',
    background: alpha(theme.palette.common.black, 0.25)
  }
}))

const FooterBar = styled(Toolbar, { shouldForwardProp: prop => !excludeProps.includes(prop) })<styledToolbarType>(
  ({ theme, drawerWidth, isSmall, hasNav }) => ({
    position: 'fixed',
    bottom: 0,
    width: `calc(100% -  ${drawerWidth}px - ${theme.spacing(2)})`,
    alignItems: 'flex-start',
    margin: 0,
    padding: theme.spacing(1),
    borderRadius: isSmall || !hasNav ? '0' : '0 0 0 10px',
    backgroundColor: theme.palette.background.default,
    '@media all': {
      minHeight: 1
    }
  })
)

export const HuiAtlasLayout: FC<AtlasLayoutProps> = ({
  gaId,
  name,
  description,
  env = 'develop',
  navigationItems = [],
  utilityNavigationItems = [],
  globalNavigationConfig = [],
  children,
  navigationConfig = {
    toggleNavOnHover: false,
    openByDefault: false
  },
  profileConfig,
  searchComponent,
  additionalNavButtons
}) => {
  const [open, setOpen] = useState<boolean>(navigationConfig.openByDefault ?? false)
  const [hasNav] = useState<boolean>(navigationItems.length > 0)

  const [drawerWidthOpen, setDrawerWidthOpen] = useState<number>(0)
  const [drawerWidthClosed, setDrawerWidthClosed] = useState<number>(0)

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [activeRoute, setActiveRoute] = useState<string>(pathname)

  const environment: envKeys = UTILS.getEnv(env)

  const [topHeight, setTopHeight] = useState<number>(0)

  useEffect(() => {
    const elm = document.getElementById('app-header')
    if (elm !== null) setTopHeight(elm.offsetHeight)
  }, [])

  useEffect(() => {
    if (gaId !== undefined) {
      ReactGA.initialize(gaId, {
        debug: environment === 'dev',
        testMode: environment !== 'prod',
        titleCase: false,
        gaOptions: {}
      })
    }
  }, [gaId, environment])

  useEffect(() => {
    if (navigationItems !== undefined && navigationItems.length > 0) {
      const defaultSelectedPath = [...navigationItems].find((o: NavigationItemProps) => o.active === true)
      if (defaultSelectedPath !== undefined) {
        navigate(defaultSelectedPath.to)
      }
    }
  }, [navigationItems])

  useEffect(() => {
    setActiveRoute(pathname)
    if (isSmall) setOpen(false)
    if (pathname !== '' && pathname !== undefined) {
      ReactGA.pageview(pathname)
    }
  }, [pathname])

  useEffect(() => {
    if (isSmall) {
      setDrawerWidthOpen(0)
      setDrawerWidthClosed(0)
    } else {
      setDrawerWidthOpen(navigationItems !== undefined && navigationItems.length > 0 ? 280 : 0)
      setDrawerWidthClosed(navigationItems !== undefined && navigationItems.length > 0 ? 80 : 0)
    }
  }, [isSmall])

  const getActiveRouteText = (pathName: string): string | undefined => {
    const route = [...navigationItems].find((o: NavigationItemProps) => o.to === pathName)
    const utilityRoute = [...utilityNavigationItems].find((o: UtilityNavigationItemProps) => o.to === pathName)
    return route?.text ?? utilityRoute?.label ?? undefined
  }

  return (
    <AtlasWrapper className='AtlasLayoutWrapper' open={open} drawerwidth={open ? drawerWidthOpen : drawerWidthClosed} fixed disableGutters>
      <SkipToContentLink variant='text' size='small' href='#atlas-main-content'>
        Skip to main content
      </SkipToContentLink>

      {navigationItems !== undefined && navigationItems.length > 0 && (
        <MainNav
          elevation={0}
          hideBackdrop
          drawerwidth={open ? drawerWidthOpen : drawerWidthClosed}
          PaperProps={{
            square: true,
            sx: {
              border: 'none',
              backgroundColor: 'primary.main',
              top: isSmall ? `${topHeight}px` : 0,
              borderRadius: isSmall ? '0 0 10px 10px !important' : 0
            }
          }}
          id='main-navigation'
          variant={isSmall ? 'temporary' : 'permanent'}
          anchor={isSmall ? 'top' : 'left'}
          open={open}
          onClose={() => (isSmall ? setOpen(false) : undefined)}
          onMouseEnter={() => navigationConfig?.toggleNavOnHover ?? setOpen(true)}
          onMouseLeave={() => navigationConfig?.toggleNavOnHover ?? setOpen(false)}
        >
          <NavList sx={{ backgroundColor: 'primary.main' }} component='nav' isSmall={isSmall}>
            {!isSmall && (
              <>
                <NavHeaderItem key='atlas-embelm-logo' sx={{ backgroundColor: 'primary.main' }}>
                  <NavHeaderItemIcon>
                    <AtlasLogo />
                  </NavHeaderItemIcon>
                  <Fade in={open} timeout={{ enter: 600, exit: 0 }}>
                    <NavHearderItemTextContainer open={open} container justifyContent='start' alignItems='center' sx={{ p: 0, m: 0 }}>
                      <NavHeaderItemText variant='h6' noWrap component={Grid} item color='common.white' sx={{ ml: 0, mb: 0 }}>
                        {name}
                      </NavHeaderItemText>
                      <NavHeaderItemSubText variant='h6' noWrap component={Grid} item color='common.white' sx={{ ml: 0, mb: 0 }}>
                        {description}
                      </NavHeaderItemSubText>
                    </NavHearderItemTextContainer>
                  </Fade>
                </NavHeaderItem>
                <Divider sx={{ mx: 0, mb: '8px' }} />
              </>
            )}

            {navigationItems.map((item: NavigationItemProps, i: number) => (
              <Tooltip
                disableFocusListener={open}
                disableHoverListener={open}
                disableInteractive={open}
                disableTouchListener={open}
                title={`${item.text}`}
                placement={isSmall ? 'bottom' : 'right'}
                arrow
                key={`menu_item_${i}`}
              >
                <NavItem
                  className={UTILS.isHttpUrl(item.to) === true ? 'exteranl-nav-link' : 'internal-nav-link'}
                  component={UTILS.isHttpUrl(item.to) === true ? 'button' : Link}
                  selected={activeRoute === item.to ?? undefined}
                  key={item.text}
                  to={UTILS.isHttpUrl(item.to) === false ? item.to : undefined}
                  href={UTILS.isHttpUrl(item.to) === true ? item.to : undefined}
                  target={UTILS.isHttpUrl(item.to) === true ? item.target : '_self'}
                  onClick={() => (UTILS.isHttpUrl(item.to) === true ? UTILS.gaLogExternalLink(`${name} MAIN NAV`, item.to) : null)}
                  dense
                >
                  <NavItemIcon sx={{ justifyContent: 'center' }}>
                    {typeof item.icon === 'string' && <HuiIcon icon={item.icon as IconName} type={item.iconType} />}
                    {isValidElement(item.icon) && item.icon}
                  </NavItemIcon>
                  {open && (
                    <Fade in={open} timeout={{ enter: 600, exit: 0 }}>
                      <NavItemText primary={<NavItemTextPrimary>{item.text}</NavItemTextPrimary>} />
                    </Fade>
                  )}
                </NavItem>
              </Tooltip>
            ))}
          </NavList>
          {additionalNavButtons != null
            ? additionalNavButtons.map((btn, i) => (
              <CollapseButton
                key={btn.text}
                container
                item
                open={open}
                justifyContent={open ? 'start' : 'center'}
                alignItems='center'
                component='button'
                onClick={btn.handleClick}
              >
                <HuiIcon icon={btn.icon as IconName} color='common.white' />
                <NavItemTextPrimary noWrap color='common.white'>
                  {open && <span style={{ marginLeft: '10px' }}>{btn.text} </span>}
                </NavItemTextPrimary>
              </CollapseButton>
            ))
            : null}
          <CollapseButton
            container
            item
            open={open}
            justifyContent={open ? 'start' : 'center'}
            alignItems='center'
            component='button'
            onClick={() => setOpen(prev => !prev)}
          >
            <HuiIcon
              icon={(!isSmall ? (open ? 'arrow-to-left' : 'arrow-to-right') : open ? 'arrow-up-to-line' : 'arrow-down-to-line') as IconName}
              color='common.white'
            />
            <NavItemTextPrimary noWrap color='common.white'>
              {open && <span style={{ marginLeft: '10px' }}>{!isSmall ? 'Collapse Sidebar' : 'Collapse'} </span>}
            </NavItemTextPrimary>
          </CollapseButton>
        </MainNav>
      )}
      <ContentWrapper id='AtlasContentWrapper' sx={{ p: 0, m: 0 }} isSmall={isSmall} hasNav={hasNav}>
        <Header
          position='sticky'
          elevation={0}
          drawerWidth={open ? drawerWidthOpen : drawerWidthClosed}
          isSmall={isSmall}
          hasNav={hasNav}
          sx={{ zIndex: 1200 }}
        >
          <HeaderBar
            open={open}
            id='app-header'
            component='header'
            sx={{ alignItems: 'center' }}
            drawerWidth={open ? drawerWidthOpen : drawerWidthClosed}
            isSmall={isSmall}
            hasNav={hasNav}
          >
            {(navigationItems === undefined || navigationItems.length === 0 || isSmall) && (
              <>
                <AtlasLogo dark={!isSmall} sx={{ mr: 2 }} />
                <Grid container direction='column' justifyContent='start' alignItems='start' sx={{ p: 0, m: 0, width: 'initial' }}>
                  <NavHeaderItemText variant='h6' noWrap component={Grid} item color='common.black' sx={{ ml: 0, mb: 0 }}>
                    {name}
                  </NavHeaderItemText>
                  <NavHeaderItemSubText variant='h6' noWrap component={Grid} item color='common.black' sx={{ ml: 0, mb: 0 }}>
                    {description}
                  </NavHeaderItemSubText>
                </Grid>
              </>
            )}

            {searchComponent !== undefined && <Grid xs={false}>{searchComponent}</Grid>}
            <Spacer />
            {utilityNavigationItems !== undefined && utilityNavigationItems.length > 0 && (
              <UtilityNavigation utilityNavigationItems={utilityNavigationItems} />
            )}
            {globalNavigationConfig !== undefined && globalNavigationConfig.length > 0 && (
              <HuiGlobalAtlasNavigation globalNavigationConfig={globalNavigationConfig} env={env} />
            )}
            <ProfileAvatar {...profileConfig} />
          </HeaderBar>

          {isSmall && navigationItems !== undefined && navigationItems?.length > 0 && (
            <HeaderBarExtension drawerWidth={open ? drawerWidthOpen : drawerWidthClosed} isSmall={isSmall} hasNav={hasNav}>
              <IconButton onClick={() => setOpen(prev => !prev)}>
                <HuiIcon icon='bars' color='common.black' size='1x' />
              </IconButton>

              <Typography variant='h6' noWrap component='div' color='common.black' sx={{ color: 'common.black', flexGrow: 1, ml: 2, mb: 0 }}>
                {getActiveRouteText(pathname)}
              </Typography>
            </HeaderBarExtension>
          )}
        </Header>

        {children ?? 'Please provide a <HuiContentWrapper> as first child to the layout'}
        <FooterBar component='footer' disableGutters drawerWidth={open ? drawerWidthOpen : drawerWidthClosed} isSmall={isSmall} hasNav={hasNav}>
          <Spacer /> {environment !== 'prod' && <Chip color='error' label={environment.toUpperCase()} size='small' />}
        </FooterBar>
      </ContentWrapper>
    </AtlasWrapper>
  )
}
