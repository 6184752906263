/**
 * This file is preprocessed to add build specific functionality for browser and nodejs
 * environments, the original file lives under the `PROJECT/hbs` folder.
 */
import { DeployStage } from './DeployStage'
import { QueryError } from './QueryError'

export interface RuntimePublicClientOptions {
  readonly protocol: string
  readonly stage?: DeployStage
  readonly domain?: string
}

export abstract class RuntimePublicClient {
  static CONFIG_DOMAIN_MASTER = 'atlas-config.mit.edu'
  static CONFIG_DOMAIN_RELEASE = 'atlas-config-test.mit.edu'
  static CONFIG_DOMAIN_DEVELOP = 'atlas-config-dev.mit.edu'

  readonly domain: string

  constructor (domain: string) {
    this.domain = domain
  }

  static create (options: RuntimePublicClientOptions): RuntimePublicClient {
    const protocol = options.protocol // eslint-disable-line @typescript-eslint/no-unused-vars
    let stage = options.stage
    let domain = options.domain

    // Default to master
    if (stage === undefined) {
      stage = 'master'
    }

    // Use defaults per stage
    if (domain === undefined) {
      if (stage === 'master') {
        domain = RuntimePublicClient.CONFIG_DOMAIN_MASTER
      } else if (stage === 'release') {
        domain = RuntimePublicClient.CONFIG_DOMAIN_RELEASE
      } else if (stage === 'develop') {
        domain = RuntimePublicClient.CONFIG_DOMAIN_DEVELOP
      } else {
        domain = RuntimePublicClient.CONFIG_DOMAIN_MASTER
      }
    }

    // Create a client from the preferred config
    let client: RuntimePublicClient | undefined

    /* Browser environment only. */
    try {
      client = new PublicClientFetchImpl(domain)
      console.log('initialized fetch')
    } catch (error: unknown) {
      console.error('error initializing client', error)
    }
    /* Browser environment only. */

    /* Node environment only. */

    // Finally default to error implementation
    if (client === undefined) {
      client = new PublicClientErrorImpl(domain)
    }

    return client
  }

  abstract queryParameter (key: string): Promise<any>
}

export class PublicClientErrorImpl extends RuntimePublicClient {
  public async queryParameter (key: string): Promise<any> {
    console.error('Application could not load required config client.')
    throw new QueryError('query could not completed, application configuration error')
  }
}

/* Node environment only. */

/* Browser environment only. */
export class PublicClientFetchImpl extends RuntimePublicClient {
  public async queryParameter (key: string): Promise<any> {
    try {
      // Prefix the key to the domain
      const fqn = `https://get.${this.domain}?key=${key}&ts=${new Date().getTime()}`
      console.log(`HTTP get config query: ${fqn}`)

      // TODO timeouts on fetch api needs to be implemented with an abort controller
      // Lookup the value using HTTP query
      const response = await window.fetch(fqn)

      // Parse the result
      if (response.status !== 200) {
        throw new Error('error response received')
      }
      return await response.json() // eslint-disable-line @typescript-eslint/return-await
    } catch (error: unknown) {
      throw new QueryError('could not load dns txt entry', error)
    }
  }
}
/* Browser environment only. */
