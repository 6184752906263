import React from 'react'

import { Box, Modal, IconButton, ModalProps, Container, Toolbar } from '@mui/material'
import { HuiIcon } from './Icon'
import { Spacer } from './Spacer'

export type HuiModalProps = {
  header?: React.ReactNode
  footer?: React.ReactNode
} & ModalProps

export const HuiModal: React.FC<HuiModalProps> = ({ header, footer, ...otherProps }) => {
  const { children, onClose } = otherProps
  const configModal = {
    ...otherProps
  }

  const style: any = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '0.6rem'
  }

  return (
    <Modal {...configModal}>
      <Box sx={{ ...style }}>
        <Toolbar
          disableGutters
          variant='dense'
          id='modal-header'
          component='header'
          sx={{ justifyContent: 'start', alignItems: 'center', height: 'initial', margin: '0 !important' }}
        >
          {header ?? header} <Spacer />
          {onClose != null && (
            <IconButton
              aria-label='Close drawer'
              id='modal-close'
              onClick={(e: React.MouseEvent<HTMLElement>) => onClose?.(e, 'escapeKeyDown')}
              sx={{ mr: 1 }}
            >
              <HuiIcon icon='times' type='fal' color='common.black' />
            </IconButton>
          )}
        </Toolbar>
        <Container id='modal-body' disableGutters sx={{ p: 0, m: 0 }}>
          {children}
        </Container>
        {footer !== undefined && <Box id='modal-footer'>{footer}</Box>}
      </Box>
    </Modal>
  )
}
