import { ParameterArrayItem, ParameterMap, ParameterValue } from './ParameterMap'

/**
 * Deployment configuration are built by combining multiple layers
 */
export class DeployConfigurationResponse {
  private _ready: boolean
  readonly data: ParameterMap

  private constructor (data: ParameterMap) {
    this._ready = false
    this.data = Object.assign({}, data)
  }

  public static create (): DeployConfigurationResponse {
    return new DeployConfigurationResponse({})
  }

  public get ready(): boolean {
    return this._ready
  }

  public set ready(value: boolean) {
    this._ready = value
  }

  public assign (data: ParameterMap): DeployConfigurationResponse {
    Object.assign(this.data, data)
    return this
  }

  public parameter (key: string): ParameterValue {
    return this.data[key]
  }

  public stringValue (key: string): string {
    const value = this.data[key]
    if (typeof value !== 'string') {
      throw new Error('value is not a string')
    }
    return value
  }

  public stringOrNullValue (key: string): string | null {
    const value = this.data[key]
    if (value !== null && typeof value !== 'string') {
      throw new Error('value is neither null or a string')
    }
    return value
  }

  public numberValue (key: string): number {
    const value = this.data[key]
    if (typeof value !== 'number') {
      throw new Error('value is not a number')
    }
    return value
  }

  public numberOrNullValue (key: string): number | null {
    const value = this.data[key]
    if (value !== null && typeof value !== 'number') {
      throw new Error('value is neither null or a number')
    }
    return value
  }

  public booleanValue (key: string): boolean {
    const value = this.data[key]
    if (typeof value !== 'boolean') {
      throw new Error('value is not a boolean')
    }
    return value
  }

  public booleanOrNullValue (key: string): boolean | null {
    const value = this.data[key]
    if (value !== null && typeof value !== 'boolean') {
      throw new Error('value is neither null or a boolean')
    }
    return value
  }

  public arrayValue (key: string): ParameterArrayItem[] {
    const value = this.data[key]
    if (!Array.isArray(value)) {
      throw new Error('value is not an array')
    }
    return value
  }
}
