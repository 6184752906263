import { ActiveRegionResponse } from '@mit/atlas-config/dist.browser'
import React, { useContext, useEffect, useState } from 'react'
import { EventBus } from '../common'
import { CheckRegionOutageBody } from '../types'

const hasActiveOutageTimeout = (): boolean => new Date().getTime() < (localStorage.getItem('OutageTimeout') ?? 0)

interface ContextProps {
  hasOutage: boolean
  activeRegionInitialized: boolean
  setHasOutage: (v: boolean) => void
  setActiveRegionInitialized: (v: boolean) => void
}

const RegionContext = React.createContext<ContextProps>({} as any)

export const RegionProvider: React.FC = (props) => {
  const [hasOutage, setHasOutage] = useState<boolean>(false)
  const [activeRegionInitialized, setActiveRegionInitialized] = useState<boolean>(false)

  useEffect(() => {
    EventBus.subscribe('CHECK_REGION_UPDATE', ({ config }: CheckRegionOutageBody) => checkRegionUpdate(config))

    return () => {
      EventBus.unsubscribe('CHECK_REGION_UPDATE')
    }
  }, [])

  const updateHasOutage = (timeoutMinutes: number) => {
    setHasOutage(true)
    // set outage timeout for next x minutes
    localStorage.setItem('OutageTimeout', `${new Date().getTime() + 60000 * timeoutMinutes}`)
  }

  const clearHasOutage = () => {
    if (!hasActiveOutageTimeout()) {
      setHasOutage(false)
      // set outage timeout for next x minutes
      localStorage.removeItem('OutageTimeout')
    }
  }

  const checkRegionUpdate = async (regionConfig: ActiveRegionResponse) => {
    if (regionConfig.failoverState === 'processing') {
      updateHasOutage(5)
    } else {
      clearHasOutage()
    }

    setActiveRegionInitialized(true)
    EventBus.dispatch('REGION_INITIALIZED')
  }

  return (
    <RegionContext.Provider value={{ hasOutage, activeRegionInitialized, setHasOutage, setActiveRegionInitialized }}>
      {props.children}
    </RegionContext.Provider>
  )
}

export const useRegionContext = () => {
  return useContext(RegionContext)
}
