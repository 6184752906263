import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Drawer, DrawerProps, Grid, GridProps, IconButton, SxProps, Theme } from '@mui/material'
import { styled } from '@mui/material/styles'

import { HuiIcon } from './Icon'
import { Spacer } from './Spacer'

export interface HuiDrawerProps extends DrawerProps {
  open: boolean
  gaTrackingName: string
  header: React.ReactNode
  headerSX?: SxProps<Theme>
  sticky?: boolean
  size?: string
  className?: string
  isLoading?: boolean
  onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'onClick') => void) | undefined
}

const HeaderWrapper = styled(Grid, { shouldForwardProp: () => true })<GridProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.headerBackground.main
}))

export const HuiDrawer: React.FC<HuiDrawerProps> = ({
  anchor = 'right',
  open = false,
  gaTrackingName = 'UNDEFINED',
  header,
  headerSX,
  sticky = true,
  size = '50%',
  isLoading = false,
  onClose = undefined,
  ...otherProps
}) => {
  const { children } = otherProps

  const drawerCSS: SxProps<Theme> = {
    width: size,
    borderBottomLeftRadius: '1rem !important',
    borderTopLeftRadius: '1rem !important'
  }

  const headerCSS: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    borderTopLeftRadius: '1rem !important',
    columnGap: 2,
    p: '20px',
    ...headerSX,
    ...(sticky && {
      position: 'sticky',
      top: 0,
      alignSelf: 'flex-start',
      zIndex: 999
    })
  }

  useEffect(() => {
    if (open) ReactGA.modalview(`drawer/${gaTrackingName}`)
  }, [open])

  return (
    <Drawer anchor={anchor} open={open} PaperProps={{ sx: drawerCSS }} {...otherProps}>
      <Grid
        container
        alignContent='flex-start'
        justifyContent='flex-start'
        columnGap={2}
        rowGap={2}
        sx={{ height: '100%', marginBottom: '10px' }}
      >
        <HeaderWrapper item xs={12} alignItems='center' sx={headerCSS}>
          {header} <Spacer />
          {(!isLoading && onClose !== undefined) &&
            <IconButton
              aria-label='Close drawer'
              sx={{ position: 'absolute !important', top: '10px', right: '20px' }}
              onClick={e => onClose?.(e, 'onClick')}
            >
              <HuiIcon icon='times' />
            </IconButton>}
        </HeaderWrapper>
        {children}
      </Grid>
    </Drawer>
  )
}
export default HuiDrawer
