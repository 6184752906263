import React, { CSSProperties, FC, HTMLAttributes } from 'react'

import { CommonProps } from '@mui/material/OverridableComponent'

export type HuiPillProps = {
  id: string
  leftSide: PillInfo
  rightSide: PillInfo
} & Partial<HTMLAttributes<HTMLElement> & CommonProps>

export interface PillInfo {
  info: string
  style?: CSSProperties | undefined
}

export const HuiPill: FC<HuiPillProps> = ({ id, leftSide, rightSide, ...otherProps }) => {
  const { className, onClick } = otherProps
  const getClasses = (): string => {
    const classes: string[] = ['pill', 'd-flex', 'justify-content-start', 'align-items-center', 'w-100']

    if (className !== undefined) classes.push(className)
    if (onClick != null) classes.push('clickable')
    return classes.join(' ')
  }

  const pillConfig = {
    ...otherProps,
    className: getClasses()
  }

  const leftStyle: CSSProperties | undefined = {
    ...leftSide.style
  }

  const rightStyle: CSSProperties | undefined = {
    ...rightSide.style
  }

  return (
    <div {...pillConfig} aria-labelledby={id}>
      <p id={id} className='pill-content' style={{ backgroundColor: '#FFFFFF' }}>
        <span className='pill-left' style={leftStyle}>
          {leftSide.info}
        </span>
        <span className='pill-right' style={rightStyle}>
          {rightSide.info}
        </span>
      </p>
    </div>
  )
}
