import React, { FC, HTMLAttributeAnchorTarget, ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Grid,
  GridProps,
  IconButton,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  styled,
  Tooltip,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { UtilityNavigationItemProps, UtilityNavigationProps } from './types'
import { HuiIcon } from '..'
import { UTILS } from '../../'

interface styledExpanderGridType extends GridProps {
  component?: any
  open: boolean
  isSmall: boolean
  itemsLength?: number
}

const styleOnlyProps: PropertyKey[] = ['isSmall', 'itemsLength']

const ExpandingGrid = styled(Grid, { shouldForwardProp: prop => !styleOnlyProps.includes(prop) })<styledExpanderGridType>(
  ({ theme, open, isSmall, itemsLength = 0 }) => ({
    maxWidth: 'auto',
    justifyContent: 'space-between',
    display: 'flex',
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '.utility-nav-item': {
      display: open ? 'inline-block' : 'none'
      // display: 'inline-block'
    },
    [`.utility-nav-item:nth-of-type(n+${itemsLength - 3})`]: {
      display: isSmall ? (open ? 'inline-block' : 'none') : 'inline-block'
    }
  })
)

interface styledNavItemType extends ListItemButtonProps {
  component?: any
  to?: string
  href?: string
  target?: HTMLAttributeAnchorTarget
}

const NavItem = styled(ListItemButton, { shouldForwardProp: prop => !styleOnlyProps.includes(prop) })<styledNavItemType>(({ theme }) => ({
  width: '100%',
  padding: '5px 10px',
  minHeight: '38px',
  tabindex: '0'
}))

const NavItemIcon = styled(ListItemIcon, { shouldForwardProp: prop => !styleOnlyProps.includes(prop) })(({ theme }) => ({
  fontSize: '18px !important',
  lineHeight: '18px !important',
  fontWeight: '900 !important',
  minWidth: '22px !important',
  marginRight: '11px',
  color: theme.palette.common.white
}))

const NavItemText = styled(ListItemText, { shouldForwardProp: prop => !styleOnlyProps.includes(prop) })(() => ({
  lineHeight: '19.07px',
  fontSize: '14px !important',
  fontWeight: '600',
  whiteSpace: 'normal',
  '&.primary': {
    lineHeight: '19.07px',
    fontSize: '14px !important',
    fontWeight: '600',
    whiteSpace: 'normal'
  }
}))

export const UtilityNavigation: FC<UtilityNavigationProps> = ({ utilityNavigationItems = [] }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [expandIcons, setExpandIcons] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const toggleUtilityNav = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (!isSmall) {
      setExpandIcons(prev => !prev)
    } else {
      if (!open) setAnchorEl(event.currentTarget)
      else setAnchorEl(null)
    }
  }

  const handleItemClick = (event?: any, clickedAction?: any): void => {
    console.log('TYPEOF clickedAction', typeof clickedAction)
    if (clickedAction !== undefined) {
      clickedAction(event)
      setAnchorEl(null)
    }
  }

  const getUtilityItem = (item: UtilityNavigationItemProps, i: number): ReactNode => {
    const maxIndex: number = utilityNavigationItems.length - 1
    const { label, icon, iconType, to, target, action } = item
    const isExteranlLink = UTILS.isHttpUrl(to)
    const className = `utility-nav-item ${
      isExteranlLink === true ? 'exteranl-link' : to !== null && to !== undefined && to !== '' ? 'internal-link' : 'internal-action'
    }`
    const itemKey = `utility-nav-item-key-${i}-${label}`

    return isSmall
      ? (
          action !== undefined
            ? (
              <NavItem
                className={className}
                component={IconButton}
                key={itemKey}
                onClick={e => handleItemClick(e, action)}
                dense
                sx={{
                  borderBottom: i < maxIndex ? '0.5px solid #C6C6C8' : 'none'
                }}
              >
                <NavItemIcon>
                  <HuiIcon className='utility-nav-link' icon={icon} type={iconType} color='primary.main' />
                </NavItemIcon>
                <NavItemText
                  sx={{
                    textDecoration: 'none !important',
                    color: 'common.black'
                  }}
                >
                  {label}
                </NavItemText>
              </NavItem>
              )
            : (
              <NavItem
                className={className}
                component={isExteranlLink === true ? 'button' : Link}
                key={itemKey}
                to={isExteranlLink === false ? to : undefined}
                href={isExteranlLink === true ? to : undefined}
                target={isExteranlLink === true ? target : '_self'}
                onClick={() => handleItemClick(undefined, isExteranlLink === true ? UTILS.gaLogExternalLink(`${label} MAIN NAV`, to) : undefined)}
                dense
                sx={{
                  borderBottom: i < maxIndex ? '0.5px solid #C6C6C8' : 'none'
                }}
              >
                <NavItemIcon>
                  <HuiIcon className='utility-nav-link' icon={icon} type={iconType} color='primary.main' />
                </NavItemIcon>
                <NavItemText
                  sx={{
                    textDecoration: 'none !important',
                    color: 'common.black'
                  }}
                >
                  {label}
                </NavItemText>
              </NavItem>
              )
        )
      : (
        <Grid item className={className} key={itemKey}>
          <Tooltip title={`${label}`}>
            {action !== undefined
              ? (
                <IconButton size='small' onClick={e => handleItemClick(e, action)}>
                  <HuiIcon icon={icon} type={iconType} style={{ margin: '0 5px' }} color='primary.main' />
                </IconButton>
                )
              : isExteranlLink === false
                ? (
                  <Link to={to ?? ''} onClick={e => handleItemClick(e)}>
                    <IconButton size='small' tabIndex={-1}>
                      <HuiIcon icon={icon} type={iconType} style={{ margin: '0 5px' }} color='primary.main' />
                    </IconButton>
                  </Link>
                  )
                : (
                  <a href={to} target={target ?? '_self'} onClick={e => handleItemClick(e)}>
                    <IconButton size='small' tabIndex={-1}>
                      <HuiIcon icon={icon} type={iconType} style={{ margin: '0 5px' }} color='primary.main' />
                    </IconButton>
                  </a>
                  )}
          </Tooltip>
        </Grid>
        )
  }

  return (
    <ExpandingGrid
      component='nav'
      container
      direction='row'
      sx={{ justifyContent: 'end', alignItems: 'center', width: 'unset !important' }}
      open={expandIcons}
      itemsLength={utilityNavigationItems.length}
      isSmall={isSmall}
    >
      {utilityNavigationItems.length > 10 && (
        <Grid item className='utility-nav-item-toggle'>
          <Tooltip title={expandIcons ? 'Show less' : 'Show more'}>
            <IconButton className='utility-nav-more-button' size='small' onClick={toggleUtilityNav}>
              <HuiIcon
                icon={expandIcons ? 'xmark' : 'ellipsis'}
                type='fal'
                style={{ margin: '0 5px' }}
                color={isSmall ? 'common.white' : 'primary.main'}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <Box tabIndex={-1} sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {isSmall
          ? (
            <Menu
              id='utility-nav-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={toggleUtilityNav}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              MenuListProps={{
                sx: { px: 2, m: 0 }
              }}
              PaperProps={{
                elevation: 1,
                sx: {
                  bgcolor: 'background.paper',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
            >
              {utilityNavigationItems.map((item: UtilityNavigationItemProps, i: number) => getUtilityItem(item, i))}
            </Menu>
            )
          : (
              utilityNavigationItems.map((item: UtilityNavigationItemProps, i: number) => getUtilityItem(item, i))
            )}
      </Box>
    </ExpandingGrid>
  )
}
