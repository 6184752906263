/* eslint-disable @typescript-eslint/no-extraneous-class */
import Amplify, { Auth } from 'aws-amplify'
import { FederatedSignInOptionsCustom } from '@aws-amplify/auth/lib/types'
import { RegionalConfig } from '../types'
import { EventBus, timeout } from '../common'

export class AuthProvider {
  public regionConfig: RegionalConfig
  constructor(regionConfig: RegionalConfig) {
    this.regionConfig = regionConfig
  }

  public configure = async (): Promise<void> => {
    const config: RegionalConfig = this.regionConfig
    const refreshCognitoToken = this.getShouldRefreshCognitoToken()

    await Amplify.configure(config.amplify)
    if (refreshCognitoToken) {
      await this.refreshToken()
    }
  }

  public getToken = async (initialize: boolean = false): Promise<string> => {
    try {
      const response = await Auth.currentAuthenticatedUser()
      const session = response.getSignInUserSession()
      const tokenValidity = (session.getAccessToken().getExpiration() - session.getAccessToken().getIssuedAt()) * 1000
      const expirationInMs = session.getAccessToken().getExpiration() * 1000
      // Keep track of expiration of access token, used in getShouldRefreshCognitoToken()
      localStorage.setItem('TokenExpiration', `${expirationInMs}`)
      localStorage.setItem('TokenValidity', `${tokenValidity}`)
      return session.getAccessToken().getJwtToken()
    } catch (error) {
      if (initialize) {
        await this.signIn()
      } else {
        // show session expired modal to user
        EventBus.dispatch('SESSION_EXPIRED')
      }
      return ''
    }
  }

  public signIn = async (): Promise<void> => {
    const config: RegionalConfig = this.regionConfig
    const options: FederatedSignInOptionsCustom = {
      customProvider: config.signInProvider
    }
    await Auth.federatedSignIn(options)
    await timeout(50000) // Amplify promise returns before the redirect happens. Need to hold here until redirect happens otherwise app's first screen renders
  }

  public signOut = async (): Promise<void> => {
    console.debug('calling Auth.signOut()')
    localStorage.removeItem('TokenExpiration')
    localStorage.removeItem('TokenValidity')
    await Auth.signOut()
  }

  public refreshToken = async (): Promise<void> => {
    localStorage.setItem('RefreshAuthentication', 'true')
    await this.signOut()
  }

  public getShouldRefreshCognitoToken = (): boolean => {
    const tokenExpiration = parseInt(localStorage.getItem('TokenExpiration') ?? '0')
    const tokenValidity = parseInt(localStorage.getItem('TokenValidity') ?? '0')
    const currentTime = new Date().getTime()

    if (tokenExpiration === 0) {
      return false
    }

    return currentTime >= tokenExpiration - tokenValidity / 2
  }
}
