import React from 'react'

import { Alert, AlertProps } from '@mui/material'

import { HuiIcon, HuiIconProps } from './Icon'

export type HuiAlertProps = {
  icon?: HuiIconProps
} & AlertProps

export const HuiAlert: React.FC<HuiAlertProps> = ({ ...otherProps }) => {
  const { children, icon, severity, variant } = otherProps

  const getIcon = (): HuiIconProps => {
    if (icon?.icon !== undefined && icon?.type !== undefined) return { icon: icon.icon, type: icon.type, color: icon.color ?? '#FFFFFF' }
    if (severity === 'success') return { icon: 'check-circle', type: 'fas', color: variant === 'filled' ? '#FFFFFF' : '#029600' }
    if (severity === 'info') return { icon: 'info-circle', type: 'fas', color: variant === 'filled' ? '#FFFFFF' : '#E1E6EC' }
    if (severity === 'warning') return { icon: 'exclamation-triangle', type: 'fas', color: variant === 'filled' ? '#FFFFFF' : '#FF8A00' }
    if (severity === 'error') return { icon: 'exclamation-circle', type: 'fas', color: variant === 'filled' ? '#FFFFFF' : '#E3124B' }

    return { icon: 'info-circle', type: 'fas', color: '#FFFFFF' }
  }

  const alertConfig = {
    ...otherProps
  }

  return (
    <Alert icon={<HuiIcon {...getIcon()} />} {...alertConfig}>
      {children}
    </Alert>
  )
}
