import React, { FC, useState } from 'react'
import {
  useMediaQuery,
  useTheme,
  Avatar,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  Grid,
  styled,
  Typography,
  TypographyProps,
  Box,
  Divider,
  Chip,
  ChipProps
} from '@mui/material'
import { ProfileAvatarProps } from './types'
import { HuiIcon } from '../Icon'

export const ProfileAvatar: FC<ProfileAvatarProps> = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const ProfileMenuTitle = styled(Typography)<TypographyProps>({
    fontWeight: 600
  })

  const ProfileMenuSubtitleRight = styled(Typography)<TypographyProps>({
    opacity: 0.75
  })

  const ProfileMenuSubTitleLeft = styled(Chip)<ChipProps>({
    borderRadius: '0.5rem'
  })

  return (
    <>
      <IconButton
        sx={{ p: 0, m: 0 }}
        id='profile-nav-button'
        aria-controls={open ? 'profile-nav-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {props.image !== undefined && props.image !== ''
          ? (
            <Avatar alt='Remy Sharp' src={props.image} />
            )
          : (
            <Avatar sx={{ bgcolor: 'primary.main' }} alt='Remy Sharp'>
              <HuiIcon icon='user' type='far' />
            </Avatar>
            )}
      </IconButton>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Menu
          sx={{
            minWidth: '250px'
          }}
          id='profile-nav-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{
            elevation: 0,
            sx: {
              minWidth: '250px',
              overflow: 'visible',
              filter: 'drop-shadow(0px 5px 8px rgba(0,0,0,0.15))',
              mt: 1.5,
              boxShadow: 'none',
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
        >
          <Grid container pb='16px' px='16px'>
            <Grid container spacing={2} pb={1}>
              <Grid item xs={12}>
                <ProfileMenuTitle variant='body1'>{props.profileMenuTitle}</ProfileMenuTitle>
              </Grid>
            </Grid>
            <Grid container alignItems='center' direction='row' spacing={2}>
              <Grid item xs>
                <ProfileMenuSubTitleLeft color='primary' label={props.profileMenuSubTitleLeft} variant='filled' />
              </Grid>
              <Grid item xs display='flex' justifyContent={isSmall ? 'inherit' : 'end'}>
                <ProfileMenuSubtitleRight>{props.profileMenuSubTitleRight}</ProfileMenuSubtitleRight>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <MenuList {...props.menuListProps}>
            {props.menuItems?.map((menuItm, index) => {
              return (
                <MenuItem key={`menu_item_${index}`} {...menuItm}>
                  {menuItm.children}
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
      </Box>
    </>
  )
}
