import React from 'react'

import { Box, Chip, IconButton, ListProps, Typography } from '@mui/material'

import { HuiIcon, HuiIconProps } from './Icon'
import { HuiList } from './List'
import { HuiListItem } from './ListItem'
import { HuiPopover, HuiPopoverProps } from './Popover'
import { HuiSkeleton } from './Skeleton'

export interface HuiActionListProps extends ListProps {
  isLoading?: boolean
  items?: HuiActionListItem[]
  onConfirm: (value: HuiActionListItem) => void
}

type Status = 'A' | 'P' | 'R' | 'N'

interface DocumentType {
  id: string
  name: string
  canApprove: boolean
}

export interface HuiActionListItem {
  id: string
  documentType: DocumentType
  icon: HuiIconProps
  fileName: string
  contentType: string
  fileType?: string
  status: Status
  comment?: {
    title: string
    message: string
  }
  infoPopover?: HuiPopoverProps
  [key: string]: any
}

export const HuiActionList: React.FC<HuiActionListProps> = ({ isLoading = false, items = [], onConfirm }) => {
  if (isLoading) {
    return (
      <HuiList hasBorder>
        <HuiSkeleton
          height='67px'
          sx={{
            transform: 'scale(1, 1)',
            borderRadius: '0.6rem'
          }}
        />
        ,
        <HuiSkeleton
          height='67px'
          sx={{
            transform: 'scale(1, 1)',
            borderRadius: '0.6rem'
          }}
        />
        ,
        <HuiSkeleton
          height='67px'
          sx={{
            transform: 'scale(1, 1)',
            borderRadius: '0.6rem'
          }}
        />
        ,
      </HuiList>
    )
  }

  return (
    <HuiList hasBorder>
      {items.map(item => (
        <HuiListItem key={item.id} hasBorder>
          <Box
            sx={{
              display: 'flex',
              columnGap: 2
            }}
          >
            {item.status === 'N'
              ? (
                <Box sx={{ padding: '4px 6px' }}>
                  <HuiIcon {...item.icon} />
                </Box>
                )
              : (
                <IconButton
                  aria-label={`Document ${item.documentType.name}, download file ${item.fileName}`}
                  onClick={() => console.log('Icon clicked')}
                  sx={{ padding: '4px' }}
                >
                  <HuiIcon {...item.icon} />
                </IconButton>
                )}
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant='h4'
                  style={{
                    fontSize: '16px',
                    marginBottom: 0,
                    fontWeight: 600
                  }}
                >
                  {item.documentType.name}
                </Typography>
                {item.comment !== undefined && (
                  <HuiPopover
                    ariaLabel={`${item.fileName} rejection reason`}
                    icon={{
                      icon: 'comment-lines',
                      type: 'fal',
                      size: 'xs'
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <Typography sx={{ fontWeight: 600 }}>{item.comment.title}</Typography>
                      <Typography>{item.comment.message}</Typography>
                    </Box>
                  </HuiPopover>
                )}
              </Box>
              <Typography variant='subtitle1' fontSize='12px'>
                {item.fileName}
              </Typography>
            </Box>
          </Box>
          <Box>
            {item.status === 'N' || item.status === 'P'
              ? (
                <>
                  <IconButton
                    aria-label='Reject'
                    {...(item.status === 'N' && {
                      disabled: true
                    })}
                    onClick={() =>
                      onConfirm({
                        ...item,
                        status: 'R'
                      })}
                  >
                    <HuiIcon icon='times-circle' type='fas' size='lg' color={item.status === 'N' ? '#bdbdbd' : '#ef5350'} />
                  </IconButton>
                  <IconButton
                    aria-label='Accept'
                    {...(item.status === 'N' && {
                      disabled: true
                    })}
                    onClick={() =>
                      onConfirm({
                        ...item,
                        status: 'A'
                      })}
                  >
                    <HuiIcon icon='check-circle' type='fas' size='lg' color={item.status === 'N' ? '#bdbdbd' : '#4caf50'} />
                  </IconButton>
                </>
                )
              : item.status === 'A'
                ? (
                  <Chip label='Approved' size='small' color='success' />
                  )
                : (
                  <Chip label='Rejected' size='small' color='error' />
                  )}
          </Box>
        </HuiListItem>
      ))}
    </HuiList>
  )
}
