import { ConfigBuilder } from './ConfigBuilder'
import { QueryError } from './QueryError'
import { DeployConfigurationResponse } from './DeployConfigurationResponse'
import { DeployPrivateClient, ParameterNotInCdkContextError } from './DeployPrivateClient'

export class DeployConfiguration$Builder extends ConfigBuilder<DeployConfiguration> {
  constructor () {
    super('deploy')
  }

  build (): DeployConfiguration {
    return new DeployConfiguration(DeployPrivateClient.create({ context: this._context }), this)
  }
}

export class DeployConfiguration {
  readonly privateClient: DeployPrivateClient
  readonly config: DeployConfiguration$Builder

  constructor (privateClient: DeployPrivateClient, config: DeployConfiguration$Builder) {
    this.privateClient = privateClient
    this.config = config
  }

  queryConfiguration (): DeployConfigurationResponse {
    if (this.config._context === undefined || this.config._stage === undefined || this.config._awsRegion === undefined || this.config._layers.length === 0) {
      throw new QueryError('[context, stage, awsRegion, layers] are required parameters for this query')
    }

    // Merge down all the layers into a single map
    const result = DeployConfigurationResponse.create()

    // If one of the parameters are not available we will mark the map as not ready
    let ready = true

    try {
      for (const key of this.config._layers) {
        let error: unknown = undefined
        const parameter = `/${this.config._stage}/${this.config._awsRegion}/${this.config._scope}/defaults/${key}`
        try {
          let layer = this.privateClient.queryParameter(parameter) // can throw ParameterNotInCdkContext
          result.assign(layer)
        } catch (error: unknown) {
          if (error instanceof ParameterNotInCdkContextError) {
            console.log('parameter not in context:', parameter)
            ready = false
          } else {
            error = error
          }
        }
        if (error !== undefined) {
          throw error
        }
      }
    } catch (error: unknown) {
      throw new QueryError('could not load specified layers', error)
    }

    result.ready = ready
    return result
  }
}
