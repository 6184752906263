import React from 'react'
import { RegionProvider } from '../context'

export function WithRegionContext<T>(WrappedComponent: React.ComponentType<T>) {
  const NewComponent = (props: T) => {
    return (
      <RegionProvider>
        <WrappedComponent {...(props as T)} />;
      </RegionProvider>
    )
  }

  NewComponent.displayName = `With(RegionContext)`

  return NewComponent
}
