import { HtmlText, HuiModal } from '@mit/hui-core'
import { Button, ButtonGroup, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { AuthProvider } from '../authorization'
import { RegionalConfig } from '../types'

interface SessionExpirationModalProps {
  regionConfig: RegionalConfig
  customBody?: string
}

export const SessionExpirationModal: React.FC<SessionExpirationModalProps> = ({ regionConfig, customBody }) => {
  return (
    <HuiModal
      open={true}
      aria-labelledby="Session Expired Modal"
      aria-describedby="Session Expired Modal"
      header={
        <Typography id="modal-title" variant="h2" color="black" padding={2}>
          Session has expired
        </Typography>
      }
      footer={
        <Grid component={ButtonGroup} container variant="outlined" aria-label="Confirmation" padding={2}>
          <Button
            variant="contained"
            disabled={false}
            onClick={() => {
              localStorage.setItem('RefreshAuthentication', 'true')
              const authProvider = new AuthProvider(regionConfig)
              authProvider.refreshToken().catch(error => console.error(error))
            }}
          >
            Ok
          </Button>
        </Grid>
      }
    >
      <Box sx={{ p: 3 }}>
        <HtmlText
          id="modal-description"
          text={customBody ?? '<p>Your session has expired. The application will reload and any progress may be lost.</p>'}
        />
      </Box>
    </HuiModal>
  )
}
