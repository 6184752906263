import React from 'react'
import { HuiDrawer } from './Drawer'

export interface HuiPersonalDetailsProps {
  open: boolean
  onClose: () => void
}

export const HuiPersonalDetails: React.FC<HuiPersonalDetailsProps> = props => {
  return (
    <>
      <HuiDrawer open={props.open} onClose={props.onClose} gaTrackingName='Storybook/PersonalDetail' header={<h3>Person Details</h3>} />
    </>
  )
}
