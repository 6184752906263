import React, { useEffect, useState } from 'react'

import { Autocomplete, AutocompleteProps, Box, CircularProgress, FormHelperTextProps, TextField, TextFieldProps } from '@mui/material'

import { Optional } from '../types/index'

export type HuiAsyncAutocompleteProps = {
  label: string
  variant?: 'standard' | 'filled' | 'outlined'
  size?: 'small' | 'medium'
  helperText?: string
  FormHelperTextProps?: Partial<FormHelperTextProps>
  onSearch: (q: string) => Promise<OptionProps[]>
} & Optional<AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput' | 'options'>

export interface OptionProps {
  id: string
  name: string
  [key: string]: string | number
}

export const HuiAsyncAutocomplete: React.FC<HuiAsyncAutocompleteProps> = ({
  label,
  variant = 'filled',
  size = 'small',
  onSearch,
  helperText,
  FormHelperTextProps,
  ...otherProps
}) => {
  const { placeholder } = otherProps
  const [open, setOpen] = useState<boolean>(false)
  const [searchKey, setSearchKey] = useState<string>('')
  const [options, setOptions] = useState<OptionProps[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSearch = async (searchKey: string): Promise<void> => {
    const items = await onSearch(searchKey)
    setOptions(items)
    setIsLoading(false)
  }

  useEffect(() => {
    if (searchKey === '') {
      setOptions([])
      return
    }
    setIsLoading(true)
    const delayedSearch = setTimeout(() => {
      ;(async (): Promise<void> => {
        await handleSearch(searchKey)
      })().catch(e => {
        console.error(e)
      })
    }, 1000)

    return () => {
      setIsLoading(false)
      return clearTimeout(delayedSearch)
    }
  }, [searchKey, onSearch])

  const onKeyUp = (event: React.ChangeEvent<HTMLInputElement> | undefined): void => {
    if (event != null) {
      const { target } = event
      const { value } = target
      if (value != null) {
        setSearchKey(String(value))
      }
    }
  }

  const configTextField: TextFieldProps = {
    label,
    variant,
    size,
    helperText,
    FormHelperTextProps
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      getOptionLabel={(option: OptionProps) => option.name}
      isOptionEqualToValue={(option: OptionProps, value: OptionProps) => option.name === value.name}
      loading={isLoading}
      onKeyUp={onKeyUp as any}
      filterOptions={(options: any[]) => {
        if (isLoading) return [{ name: 'Loading...' }]
        if (searchKey === '') return [{ name: placeholder ?? 'Search' }]
        return options
      }}
      getOptionDisabled={() => !!(isLoading || searchKey === '')}
      renderInput={params => (
        <TextField
          {...params}
          {...configTextField}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading
                  ? (
                    <Box sx={{ color: '#696969' }}>
                      <CircularProgress color='inherit' size={20} />
                    </Box>
                    )
                  : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      {...otherProps}
    />
  )
}
