import React from 'react'

import { Skeleton } from '@mui/material'

interface Props {
  text: string
  id?: string
  isLoading?: boolean
}

export const HtmlText: React.FC<Props> = ({ text, id, isLoading }) => {
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(text)
  return (
    <>
      {isLoading === true
        ? (
          <Skeleton variant='text' height='30px' />
          )
        : (
          <>
            {isHTML
              ? (
                <div id={id} className='html-text-detailed' dangerouslySetInnerHTML={{ __html: text }} />
                )
              : (
                <p id={id} className='html-text' style={{ margin: 0, fontWeight: 400, fontSize: '1rem', lineHeight: 1.5 }}>
                  {text}
                </p>
                )}
          </>
          )}
    </>
  )
}
