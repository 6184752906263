import { ConfigScope } from './ConfigScope'
import { DeployStage } from './DeployStage'
import { RegionRole } from './RegionRole'
/* Node environment only. */

type ConfigBuilder$QueryWith = 'dns' | 'http'

/**
 * Constructs a new atlas config client which will retrieve config with DNS or HTTP.
 * The DNS method should be used for services internally to AWS, i.e. Lambda, EC2 etc.
 * Either the DNS or HTTP method may be used for any external clients, although this
 * might depend on the runtime requirements of the client.
 */
export abstract class ConfigBuilder<T> {
  readonly _scope: ConfigScope

  _context?: any
  _profile: string = 'default'
  _queryWith: ConfigBuilder$QueryWith = 'http'

  _stage: DeployStage = 'master'
  _awsRegion: string = 'us-east-1'
  _regionRole: RegionRole = 'primary'
  _layers: string[] = []
  _application?: string
  _component?: string
  _version?: string

  constructor (scope: ConfigScope) {
    this._scope = scope
  }

  /* Node environment only. */

  queryWithDns (): ConfigBuilder<T> {
    this._queryWith = 'dns'
    return this
  }

  queryWithHttp (): ConfigBuilder<T> {
    this._queryWith = 'http'
    return this
  }

  authWithProfile (profile: string): ConfigBuilder<T> {
    this._profile = profile
    return this
  }

  stage (stage: DeployStage): ConfigBuilder<T> {
    this._stage = stage
    return this
  }

  awsRegion (awsRegion: string): ConfigBuilder<T> {
    this._awsRegion = awsRegion
    return this
  }

  regionRole (regionRole: RegionRole): ConfigBuilder<T> {
    this._regionRole = regionRole
    return this
  }

  layers (layers: string[]): ConfigBuilder<T> {
    this._layers = layers
    return this
  }

  application (application: string): ConfigBuilder<T> {
    this._application = application
    return this
  }

  component (component: string): ConfigBuilder<T> {
    this._component = component
    return this
  }

  version (version: string): ConfigBuilder<T> {
    this._version = version
    return this
  }

  abstract build (): T
}
