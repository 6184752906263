/**
 * Error occurred during query of config.
 */
export class QueryError extends Error {
  readonly cause?: unknown
  constructor (message: string, cause?: unknown) {
    super(message)
    this.cause = cause
    Error.captureStackTrace(this, QueryError.constructor)
  }
}
